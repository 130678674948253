<template>
  <div>
    <section
      id="hero-home"
      class="hero has-text-centered is-dark hero-emphasis"
    >
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column feature-text">
              <h1 class="title has-text-white is-size-1">Support<br /></h1>
              <h3 class="subtitle has-text-light-grey is-size-5">
                Responsive and professional support for our clients.
              </h3>
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="feature-content columns">
      <div class="column is-size-4">
        <h1>
          We deliver <span>high performing</span> solutions that improve
          <span>processes</span> and <span>communications</span> between our
          clients and their external stakeholders. We ensure long-term project
          success by <span>monitoring</span>, <span>caring</span> for and
          <span>diligently</span>
          sustaining a system to truly realise its full potential.
          <br /><br />
          Our <span>professional</span> support staff are on hand to fully
          resolve any support issues as they occur, to help
          <span>ensure</span> that business doesn't skip a beat. <br /><br />
          We offer ongoing support agreements for all of our
          <span>solutions</span> and <span>services</span> which clearly specify
          support <span>levels</span>, <span>time frames</span> and
          <span>procedures</span>, delivering certainty and peace of mind to our
          clients allowing them to focus on their business. <br /><br />
          Our support services are available to all of our clients and we offer
          flexible support packages to cover all of our solutions and services.
        </h1>
      </div>
    </section>
  </div>
</template>

<style scoped>
@import "../../styles/feature-content.scss";
.hero-body {
  background: url("/img/service-banner/support-banner.jpg");
  background-size: cover;
  background-position-y: center;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: darken;
}
#hero-home {
  position: relative;
}
#hero-home:after {
  content: "\A";
  background-image: url("/img/service-banner/support-banner.jpg");
  background-size: cover;
  background-position-y: center;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: darken;
  height: 100%;
  width: 100%;
  position: absolute;
}
</style>

<script>
export default {
  name: "Support"
}
</script>
